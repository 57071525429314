.root {
  max-width: 60rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 15px;

  /*
  padding: 8px 12px;
  background: #f3f3f3;
  border-radius: 6px;
  */

  p:last-child {
    margin-bottom: 0;
  }

  margin-bottom: 1rem;
}
