@import '../theme.scss';

.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #fff;
  border: none;
  padding: 0;
  margin-left: $default-padding-horizontal;

  &:focus {
    outline: none; 
  }

  &:hover {
    color: #f08e05 !important;
    text-decoration: underline !important;
  }

  &.active {
    color: $primary-color !important;
    font-weight: bold;
    background: #fff;
  }
}

.contents {
  padding: $default-padding;
  padding-bottom: 25vh;
}
