.root {
  margin-bottom: 20px !important;
}

.caption {
  margin-bottom: 0.5rem;
  font-size: 18px;
}

.option {
  margin-bottom: 10px;
}
