.root {

  width: 100%;
  overflow-x: auto;

  tbody td:first-child {
    text-align: right;
  }

  th, td {
    padding: .5rem .5rem .3rem .5rem;
  }
}

.actionColumn {
  width: 185px;
  padding: 0 .3rem .3rem 0 !important;

  button {
    margin: .3rem 0 0 .3rem;
  }
}
