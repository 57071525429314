@import '../../theme.scss';

.root {
  width: calc(50% - 40px);
  min-width: 300px;

  &:first-child {
    margin-right: 2 * $default-padding-horizontal;
    margin-bottom: $default-padding-vertical;
  }

  h3 {
    height: 55px;
  }

  p {
    margin-top: $default-padding-vertical;
    font-size: 14px;
  }

}

.tokenInput {
  height: 38px;
  padding: 8px;
  font-family: monospace;
  font-size: 21px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 2px;
}
