$primary-color: #f39611;
$primary-highlight: #f39611;
$secondary-color: #ffcc7f;

$gridlines-border: solid #aeaeae 1px;

$default-padding-horizontal: 20px;
$default-padding-vertical: 20px;
$default-padding: $default-padding-vertical $default-padding-horizontal;

$menu-item-padding: 8px 30px 8px 20px;
$menu-item-color: #000;
$menu-item-active-color: $primary-highlight;
$menu-item-disabled-color: #898989;
$menu-item-background-hover: #f7f4f1;

$nav-color: #f7f4f1;
