@import '../theme.scss';

.root {
  background: #f00;
}

.sidebar {
  border-right: $gridlines-border;
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  background: #fff;
}

.toggle {
  z-index: 20;
  position: absolute;
  left: 18px;
  top:  12px;
  appearance: none;
  border: none;
  background: #fff;
  border-radius: 4px;
  border: solid #bbb 1px;
  width: 38px;
  height: 36px;
  font-size: 22px;
  color: #007bff;
  padding: 0;

  img {
    margin-top: -2px;
    width: 23px;
  }
}

.wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  height: 100%;
  overflow-y: auto;
}

.navigation {
  width: 100%;
  align-self: flex-end;
}

.loadingModal {
  font-size: 20px;
  padding: $default-padding;
}

@media print {
  .sidebar, .toggle {
    display: none;
    width: 0;
  }
  .wrap {
    position: fixed;
    left: 20mm;
    right: 20mm;
    top: 20mm;
    bottom: 25mm;
  }
  .main {
    overflow-y: initial;
  }
}
