@import '../theme.scss';

.root {
  font-size: 18px;
  float: right;
  width: 100%;
  min-width: 300px;
  max-width: calc(100% - 40px);
  background: $primary-color;
  color: #fff;
  padding: 15px;
  top: 0;

  /*
  width: calc(100% - 58px);
  transform: skew(-5deg);
  margin: 20px 28px 20px 0;
  */

  margin: $default-padding;

  p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
  }
}

.impact {
  line-height: 1.2;
  font-size: 24px;
  display: block;
  font-weight: 500;

  a, a:hover {
    color: #fff;
  }
}
