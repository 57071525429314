@import '../theme.scss';

.root {
  font-size: 20px;
  line-height: 42px;
  padding-bottom: 1px;
  text-align: center;
  background: $secondary-color;
  border-bottom: $gridlines-border;
  transition: all 300ms ease-in-out;

  p {
    display: inline-block;
    margin: 0 20px 0 0;
  }
}

.active {
  transition: all 300ms ease-in-out;
}
