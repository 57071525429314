.root {

  table {
    width: auto;
  }

  thead tr th {
    border-top: none;
  }

  th,
  td {
    min-width: 80px;
    padding: 0.5rem;
    text-align: center;
  }

  th:first-child {
    width: 200px;
    text-align: left;
  }
}

.tableWrap {
  max-width: 100%;
  overflow-x: auto;
}

.check {
  text-align: center;
  div {
    margin: 0 !important;
  }
  input {
    margin: 0 !important;
  }
}
