.root {
  width: 100%;
  max-width: 2000px;
  overflow-x: auto;

  & table {
    & thead tr {
      height: 120px;
    }
    & thead th {
      max-width: 0;
      position: relative;
    }
    & thead th,
    td {
      text-align: center;
    }
    & th:first-child,
    & td:first-child {
      text-align: left;
      max-width: 200px;
    }
  }

  td,
  th {
    padding: 0.2rem;
  }

  tbody th {
    padding-left: 6px;
  }
  tbody tr:hover {
    background: #eaeff7;
  }
}

.rotatedHeader {
  transform: rotate(-45deg);
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  max-width: 100px;
  overflow: hidden;
  transform-origin: -36px 12px;
  position: absolute;
  max-height: 36px;
  line-height: 110%;
}

.check,
.check input {
  margin: 0;
}
