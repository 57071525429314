@import '../../theme.scss';

.root {
  padding: $default-padding;
  padding-bottom: 25vh;
}

.sticky {
  padding-bottom: $default-padding-vertical;
}

/* sections next to each other
.wrap {
  display: flex;
  flex-wrap: wrap;
}

.wrap > * {
  margin-right: 20px;
}
*/

@media print {
  .root, .sticky {
    padding: 0;
  }
}
