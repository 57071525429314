@import '../theme.scss';

.root {
  position: relative;
  height: 60px;
  padding: $default-padding;
  border-bottom: $gridlines-border;
  z-index: 5;

  h2 {
    transition: margin 300ms;
    margin-left: 50px;
    font-size: 18px !important;
    padding-right: 150px;
    margin-top: -20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 59px;
  }

  h1 a {
    border: $gridlines-border;
    padding: 5px 8px 8px 8px;
    background: #fff;
    position: absolute;
    top: -1px;
    right: 20px;
    z-index: 10;
  }

  img {
    height: 56px;
  }
}

.sidebarOpen h2 {
  margin-left: 0;
  transition: margin 300ms;
}

@media (max-width: 575px) {
  .root {
    h1 a {
      border: none;
      padding: 0;
      background: transparent;
      top: 1px;
      right: 13px;
    }

    img {
      height: 46px;
    }
  }
}

@media print {
  .root {
    height: auto;
    padding: 0;
    border-bottom: none;

    img {
      position: fixed;
      right: 10mm;
      top: 10mm;
      height: 60px;
      border: none;
    }
  }
}
