.root {
  margin-bottom: 20px;
}

.label {
  margin: 0 6px;
  display: inline-block;
  width: 30px;
  text-align: center;
}

.picker {
  max-width: 120px;
}

.caption {
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;

  sup {
    position: relative;
    right: inherit;
    top: -4px;
  }
}

:global(.react-datepicker__close-icon::after) {
  background: transparent url('../icons/x_white.svg') center center no-repeat;
  background-size: 8px 8px;
  content: ' ';
}
/*

https://github.com/Hacker0x01/react-datepicker/issues/1480

*/
.disabled :global(.react-datepicker__close-icon) {
  display: none;
}

.disabled input:focus {
  border-color: transparent;
  box-shadow: none;
}
