.root {
  border: solid #59a9ff 1px;
  margin: 0.5rem;
  position: relative;
  padding: 1.5em 0 0 1.5em;  
}

.formElement {
  padding: 0 3px;
  background: #e0eefd;
  border: solid #59a9ff 1px;
  display: inline-block;
  border-top: 0;
  border-left: 0;
  border-radius: 0;
  color: #007bff;
  position: absolute;
  top: 0;
  left: 0;
}
