body {
  margin: 0;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 24px !important;
}

h3 {
  font-size: 22px !important;
}

h4 {
  font-size: 20px !important;
}

h5 {
  font-size: 17px !important;
  margin-bottom: 0.75rem !important;
}

p {
  max-width: 40em;
}

a {
  color: #f08e05;
  font-weight: 500;
  text-decoration: none;
}

a:hover {
  color: #f08e05;
  text-decoration: underline;
}

textarea {
  resize: none;
}

.form-group {
  /* margin-bottom: 0.5rem !important; */
}

.form-check-label {
  margin-left: 0.3rem;
}

.form-check-input {
  margin-top: .25rem;
}

.alert p:last-child {
  margin-bottom: 0;
}

.react-datepicker__close-icon::after {
  background-color: #dc3545;
}

.labelDetail {
  font-size: 14px;
  display: block;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.bg-gray {
  background: #636869;
  color: #fff;
  font-weight: bold;
}

.bg-ok {
  background: #80ba00;
  color: #fff;
  font-weight: bold;
}

.form-control {
  color: #212529;
}

select.form-control {
  width: auto;
}

@media (min-width: 576px) {
  .col-form-label {
    max-width: 200px !important;
    text-align: right;
  }

  input[type=text], input[type=email], textarea {
    max-width: 600px;
  }
}

@media print {
  #root {
    margin: 25mm 25mm 25mm 25mm;
  }
}
