.terms {
  background: #f5f5f5;
  padding: 20px;
  max-height: 40vh;
  overflow-y: scroll;
  margin-bottom: 1rem;

  p {
    max-width: inherit;
  }
}
