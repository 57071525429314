.root {
  width: 100%;
}

.number {
  label {
    max-width: 260px !important;
  }
  input {
    max-width: 100px;
  }
}

.labelOnTop {
  display: block;
  label {
    max-width: none !important;
    text-align: left;
    padding-left: 0;
  }
}

.inputWrap {
  display: flex;
}

.postfix {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}
