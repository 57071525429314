@import '../theme.scss';

.root {
  margin-top: 59px;

  h4 {
    display: none;
  }

  h3 {
    position: absolute;
    top: 0;
    left: 70px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    height: 59px;
    max-width: 160px;
    margin: 0;
    margin-bottom: 1rem;
    color: #333;
    font-size: 18px !important;
    hyphens: auto;
    line-height: 100%;
    word-break: break-word;

    display: none;
  }
}

ul {
  padding: 0;
  list-style-type: none;
}

li:focus-within {
  background: $menu-item-background-hover;

  a {
    color: $menu-item-active-color;
  }
}

li {
  position: relative;
  padding: 0;
  font-size: 18px;
  background: #fff;
  border-bottom: $gridlines-border;

  &:first-child {
    border-top: $gridlines-border;
  }

  a {
    display: block;
    padding: $menu-item-padding;
    color: $menu-item-color;

    &:hover {
      background: $menu-item-background-hover;
      color: $primary-highlight;
      text-decoration: none;
    }
  }

  a:focus {
    color: $menu-item-active-color;
    outline: none;
  }

  &.active,
  &.active:hover {
    a {
      background: #fff;
      color: $menu-item-active-color;
      font-weight: bold;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
      content: ' ';
      background: $primary-color;
    }
  }
}

li span.currentPage {
  display: block;
  padding: $menu-item-padding;
  color: $menu-item-disabled-color;
}

li span.statusIcon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 14px;
  right: 14px;
  background: transparent none no-repeat;
}

li span.statusIcon:hover {
    cursor: help;
}

li span.ok {
  background-image: url('./icons/ok.svg');
}

li span.error {
  background-image: url('./icons/error.svg');
}
