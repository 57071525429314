@import '../theme.scss';

.root {
  z-index: 6;
  padding: $default-padding;
  overflow: auto;
  border-top: $gridlines-border;
  background: #fff;

  > div {
    max-width: 830px;
    flex-wrap: nowrap;
  }

  .center {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;

     > div {
      position: relative;
      width: 30vw;
    }
  }

  .navigation {
    background: $primary-color;
    border-color: $primary-color;

    &:hover {
      background: $primary-highlight;
      border-color: $primary-highlight;
    }

    &:focus {
      background: $primary-highlight;
      box-shadow: 0 0 0 0.2rem #fa9a0f40;
      border-color: $primary-highlight;
    }

    &:active {
      background: $primary-highlight !important;
      box-shadow: 0 0 0 0.2rem #fa9a0f40 !important;
      border-color: $primary-highlight !important;
    }
  }
}

@media print {
  .root {
    display: none;
  }
}
