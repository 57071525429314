.root {
  display: inline-block;
  color: #000;
  font-family: monospace;
  font-size: 14px;
}

.name {
  padding: 1px 4px;
  background: #ffaaffaa;
  border: solid #faf 1px;
  border-radius: 2px;
}

.value {
  padding: 1px 4px;
  background: #fdffe0;
  border: solid #fd7 1px;
  border-radius: 2px;
}
