.root {
  ul {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 0;
    color: #f64236;
    font-size: 14px;
    background: transparent;
    border: none;
  }
}
